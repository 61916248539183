import './App.css';
import {Questions} from "./Questions";

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <h1>Welle kulturelle Aaeignigstyp bisch du?</h1>
            </header>
            <Questions/>
        </div>
    );
}

export default App;
